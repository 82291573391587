import React from "react"
import { graphql } from 'gatsby'
import Img from "gatsby-image/withIEPolyfill"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Header from "../components/Header"
import Footer from "../components/Footer"
import International from "../components/International"
import LocationMap from "../components/LocationMap"

export default ({ data }) => {
  const page = data.strapiCenturionInternational
  const locations = data.allStrapiTheCenturionLounge.nodes
  let sources = []
  if (page.heroImage) {
    sources = [
      page.heroImage.localFile.childImageSharp.mobileImage,
      {
        ...page.heroImage.localFile.childImageSharp.desktopImage,
        media: `(min-width: 676px)`,
      },
    ]
  }
  return (
    <Layout>
      <SEO metaTitle={page.metaTitle} />
      <Header />
      {page.heroImage &&
        <div className="hero">
          <Img fluid={sources} />
        </div>
      }
      <div className="container container--med island--dubs text-center">
        <h1 className="h2">{page.title}</h1>
        <p dangerouslySetInnerHTML={{__html: page.intro}} />
      </div>
      {page.airports && page.modalHeadings &&
        <International modalHeadings={page.modalHeadings} locations={locations} airports={page.airports} />
      }
      <LocationMap />
      <div className="container container--narrow landmark text-center">
        <div className="legal" dangerouslySetInnerHTML={{__html: page.footnote}} />
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiCenturionInternational {
      metaTitle
      title
      heroImage {
        localFile{
          childImageSharp {
          desktopImage: fluid(maxWidth: 1200, maxHeight: 420) {
            ...GatsbyImageSharpFluid
          }
          mobileImage: fluid(maxWidth: 676, maxHeight: 540) {
            ...GatsbyImageSharpFluid
          }
        }
        }
      }
      intro
      footnote
      modalHeadings {
        locationHeading
        hoursHeading
        accessHeading
      }
      airports {
        airportCode
        airportName
        shortAirportName
        gridBackgroundImage {
          localFile {
            childImageSharp {
            fluid(maxWidth: 676) {
              ...GatsbyImageSharpFluid
            }
          }
          }
        }
        lounges {
          terminal
          location
          hours
          access
        }
      }
    }
    allStrapiTheCenturionLounge(
      filter: {
        showInListing: { eq: true }
        isInternational: { eq: true }
      }
      sort: {
        fields: [shortAirportName]
        order: ASC
      }
    ) {
      nodes {
        airportCode
        shortAirportName
        showInListing
        gridBackgroundImage {
          localFile{
						childImageSharp {
            fluid(maxWidth: 676) {
              ...GatsbyImageSharpFluid
            }
          }
          }
        }
      }
    }
  }
`
