import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image/withIEPolyfill"

export default class International extends React.Component {
  constructor() {
    super()
    this.state = { openModal: null }
    this.closeBtnRefArray = []
  }

  openModal = (index, ref) => {
    this.setState({ openModal: index})
    setTimeout(
      function() {
        this.closeBtnRefArray[index].current.focus()
      }.bind(this),
      0
    );
  }

  closeModal = () => {
    this.setState({ openModal: null})
  }

  closeWithEscape = (ev) => {
    if (ev.keyCode === 27) {
      this.closeModal()
    }
  }

  render() {
    this.closeBtnRefArray = []
    const { modalHeadings, locations, airports } = this.props
    return (
      <div className="container">
        <div className="grid-wrapper">
          {locations.map( (location, index) => {
            return (
              <div key={index} className="grid lap-one-third desk-one-quarter">
                <BackgroundImage
                  Tag="div"
                  className="location-card landmarklet"
                  fluid={location.gridBackgroundImage.localFile.childImageSharp.fluid}
                >
                  <div className="location-card-overlay"></div>
                  <div className="location-card-content">
                    <h3 className="h2">{location.shortAirportName} <br />({location.airportCode})</h3>
                    <Link to={"/locations/" + location.airportCode.toLowerCase() + "/"} className="btn white">Explore Lounge</Link>
                  </div>
                </BackgroundImage>
              </div>
            )
          })}
          {airports.map( (airport, index) => {
            const newRef = React.createRef()
            this.closeBtnRefArray.push(newRef)
            return (
              <div key={index} className="grid lap-one-third desk-one-quarter">
                <BackgroundImage
                  Tag="div"
                  id={airport.airportCode.toLowerCase()}
                  className="location-card location-card--intl landmarklet"
                  fluid={airport.gridBackgroundImage.localFile.childImageSharp.fluid}
                >
                  <div className="location-card-overlay"></div>
                  <div className="location-card-content">
                    <h3 className="h2">{airport.shortAirportName} <br />({airport.airportCode})</h3>
                    <button className="btn white" onClick={() => this.openModal(index, newRef)}>View Details</button>
                  </div>
                </BackgroundImage>
                <div role="dialog" className={this.state.openModal === index ? 'modal modal--med open': 'modal modal--small'}>
                  <div className="modal-content">
                    <button ref={newRef} className="close" onClick={() => this.closeModal()} onKeyDown={this.closeWithEscape}><i className="icon-close"></i><span className="visuallyhidden">Close</span></button>
                    <div className="grid-wrapper grid-wrapper--full">
                      <div className="grid lap-two-fifths">
                        <Img className="hidden-below-lap" fluid={airport.gridBackgroundImage.localFile.childImageSharp.fluid} alt="lounge interior" />
                      </div>
                      <div className="grid lap-three-fifths islet">
                        <div className="inset-content">
                          <h4 className="h2 landmarklet">{airport.airportName} ({airport.airportCode})</h4>
                          {airport.lounges.map( (lounge, index) => {
                            return (
                              <div key={index}>
                                {lounge.terminal &&
                                  <h5 className="h3">{lounge.terminal}</h5>
                                }
                                <p><strong>{modalHeadings.locationHeading}:</strong> <br/>{lounge.location}</p>
                                <p><strong>{modalHeadings.hoursHeading}:</strong> <br/>{lounge.hours}</p>
                                <p><strong>{modalHeadings.accessHeading}:</strong> <br/><span dangerouslySetInnerHTML={{__html: lounge.access}} /></p>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-overlay" onClick={() => this.closeModal()} aria-hidden="true"></div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

International.propTypes = {
  modalHeadings: PropTypes.object.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  airports: PropTypes.arrayOf(PropTypes.object).isRequired,
}
